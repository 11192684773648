import { Injectable, inject } from "@angular/core";
import { InferredRequests } from "@zixi/zen-rpc";
import { ZenApiService } from "../../../services/zen-rpc-service";

@Injectable({
    providedIn: "root"
})
export class GridVisualizationService {
    private zenApiService = inject(ZenApiService);

    async getGridVisualizationList() {
        try {
            const result = await this.zenApiService.client.customVisualization.getList();
            return result.body.result;
        } catch (e) {
            return [];
        }
    }

    async createGridVisualization(body: InferredRequests["customVisualization"]["create"]["body"]) {
        try {
            const result = await this.zenApiService.client.customVisualization.create({ body });
            return result.body.result;
        } catch (e) {
            return false;
        }
    }

    async updateGridVisualization(id: number, body: InferredRequests["customVisualization"]["update"]["body"]) {
        try {
            const result = await this.zenApiService.client.customVisualization.update({
                params: { id },
                body
            });
            return result.body.result;
        } catch (e) {
            return false;
        }
    }
}
